import { FirebaseAppFirestore } from '@/app/shared/firebase/firebase-app';
import firebaseNames from '@/app/shared/utilities/firebase-names';
import { DocumentReference, DocumentSnapshot } from '@firebase/firestore-types';

/**
 * Get system's profiles for admin.
 */
export const getSystemProfilesRefsAction = async () => {
  const snap = await FirebaseAppFirestore
    .collection(firebaseNames.SYSTEM_PROFILES)
    .get();
  if (snap.empty) {
    return [];
  }
  return snap.docs.map((doc) => doc.data().PROFILE_REF as DocumentReference);
};
