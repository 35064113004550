




















































import { Component, Vue } from 'vue-property-decorator';
import { DocumentReference } from '@firebase/firestore-types';

import { SYSTEM_STATUS } from '@/app/shared/utilities/object-factory';
import { AdminRoutes } from '@/app/shared/utilities/routes-names';
import { CompanyProfile } from '@/app/shared/utilities/static-types';
import { getSystemProfilesRefsAction } from '@adminProfiles/shared/actions/CompanyProfileAdminActions';

import namespace from '@/app/shared/store/namespace';

@Component
export default class PendingProfilesHome extends Vue {
  public loading = true;

  public pendingProfiles: CompanyProfile[] = [];

  public profileViewRoute = AdminRoutes.PENDING_PROFILES_VIEW;

  private refs: DocumentReference[] = [];

  public async created() {
    try {
      this.loading = true;
      this.refs = await getSystemProfilesRefsAction();
      const promises = this.refs.map(async (ref) => {
        const snap = await ref.get();
        return snap.data() as CompanyProfile;
      });
      this.pendingProfiles = await Promise.all(promises);
    } finally {
      this.loading = false;
    }
  }

  public goToCompanyProfileView(index: number) {
    const targetRef = this.refs[index];
    this.$store.commit(
      `${namespace.CompanyProfileModule}/setCurrentCompanyProfileRef`,
      targetRef,
    );
  }
}
